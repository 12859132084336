<template>
  <footer>
    <div class="copyright-container">
      <div
          class="back-to-top"
          role="button"
          @click="backToTop">
        <i class="fal fa-arrow-up" aria-hidden="true"/>
        Back to Top
      </div>
      <div class="copyright" v-html="copyright"/>
    </div>

    <div class="social-container">
      <router-link :to="{ path: contactLink }">
        <i class="far fa-envelope margin-right" aria-hidden="true"/>
      </router-link>
      <a
          :href="instagramLink"
          rel="noopener noreferrer"
          target="_blank">
        <i
          class="fab fa-instagram margin-right"
          aria-hidden="true"></i>
      </a>
      <a
          :href="facebookLink"
          rel="noopener noreferrer"
          target="_blank">
        <i class="fab fa-facebook-f" aria-hidden="true"/>
      </a>
    </div>
  </footer>
</template>

<script>
import api from '@/api'

export default {
  name: 'Footer',
  data() {
    return {
      footerLoaded: false,
      copyright: '© Nick Bayless',
      contactLink: '/contact',
      instagramLink: 'https://www.instagram.com/nick_bayless_photography/',
      facebookLink: 'https://www.facebook.com/Nick-Bayless-Photography-180923651970525/'
    }
  },
  mounted() {
    this.loadFooterData()
  },
  methods: {
    loadFooterData() {
      api.Footer.loadFooter()
          .then(response => response.data.data.globals.footer)
          .then(response => {

            localStorage.setItem('footer', JSON.stringify(response))

            this.setFooterFields(response)
          }).catch(() => {
            const response = JSON.parse(localStorage.getItem('footer'))
            this.setFooterFields(response)
          })
    },
    setFooterFields(response) {
      this.copyright = response.textInput
      this.contactLink = response.footerLinks[0].emailLink
      this.instagramLink = response.footerLinks[0].instagramLink
      this.facebookLink = response.footerLinks[0].facebookLink
    },
    backToTop() {
      document.getElementById('page').scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply items-center;

  margin: 15px 0 67px;
  font-family: 'UniversNext', serif;
  background-color: #ffffff;

  .copyright-container {
    @apply flex;
    @apply justify-around;
    @apply w-full;
    line-height: 16px;
    font-size: 14px;
    color: #303030;
    margin-bottom: 19px;

    .back-to-top {
      @apply inline-block;
      cursor: url(~@/assets/cursor-dark.svg) 14 16,auto;
      margin-right: 40px;
    }

    .copyright {
      @apply inline-block;
    }
  }

  .social-container {
    a i {
      color: #020303;
      font-size: 26px;

      &.margin-right {
        margin-right: 20px;
      }
    }
  }

  /* Undo Mobile fix */
  @media screen and (min-width: 480px) {
    @apply flex-row;
    @apply justify-between;

    .copyright-container {
      @apply w-auto;
      @apply mb-0;
    }
  }

  /* Tablet */
  @media screen and (min-width: 768px) {
    .copyright-container {

      .back-to-top {
        margin-right: 100px;
      }
    }

    .social-container {
      a i {
        font-size: 26px;
      }
    }
  }
}
</style>