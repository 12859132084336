<template>
  <h2>{{ title }}</h2>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: 30px;
  font-family: 'BlackerDisplay', serif;
  color: #000000;
  line-height: 41px;
  letter-spacing: 0.4px;
  padding: 4px 0 24px 0;

  @media screen and (min-width: 768px) {
    font-size: 64px;
    padding: 52px 30px 72px;
    line-height: 46px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 80px;
    padding: 67px 30px 107px;
  }
}
</style>
