<template>
  <div class="contact-page">
    <PageTitle v-if="showTitle" :title="title" />

    <div class="split-page">
      <div class="image-container">
        <img ref="profilePicture" :src="profilePicture.url" :alt="profilePicture.title">
        <!--<img class="logo-svg" src="@/assets/svg/nick-bayless-logo.svg" alt="Nick Bayless Watermark">-->
      </div>
      <div class="contact-info-container">
        <ul
            class="links"
            v-if="links.length">
          <li v-for="(link, index) in links" :key="index">
            <a :href="link.linkUrl" :target="link.openNewWindow ? '_blank' : '_self'">{{ link.linkTitle }}</a>
          </li>
        </ul>

        <p class="address" v-html="addressFormatted"></p>
      </div>
      <div class="about-nick-container">
        <p class="about-nick" v-html="aboutNickFormatted"></p>
      </div>


    </div>

    <Footer/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api'
import PageTitle from '@/components/ui/PageTitle'
import Footer from '@/components/footer/Footer'

export default {
  name: 'Contact',
  components: { PageTitle, Footer },
  data() {
    return {
      title: '',
      showTitle: false,
      profilePicture: {
        url: null,
        title: null
      },
      links: [],
      address: '',
      aboutNick: ''
    }
  },
  computed: {
    addressFormatted() {
      return this.nl2br(this.address)
    },
    aboutNickFormatted() {
      return this.nl2br(this.aboutNick)
    }
  },
  mounted () {
    this.initContactPage()
  },
  methods: {
    ...mapActions('page', {
      toggleShowPage: 'toggleShowPage'
    }),
    initContactPage() {
      api.Contact.loadContactPage()
        .then(response => response.data.data.entry)
        .then(entry => {
          localStorage.setItem('contact', JSON.stringify(entry))
          this.updatePageTitle(entry.seo.title)
          this.setEntry(entry)
        }).catch(() => {
          const entry = JSON.parse(localStorage.getItem('contact'))
          this.updatePageTitle(entry.seo.title)
          this.setEntry(entry)
        })
    },
    setEntry(entry) {
      this.$refs.profilePicture.onload = this.toggleShowPage
      this.title = entry.title
      this.showTitle = entry.showTitle
      this.profilePicture = entry.imageUpload[0]
      this.links = entry.links
      this.address = entry.textarea || ''
      this.aboutNick = entry.largeTextarea || ''
    },
    nl2br(str) {
      if (typeof str === 'undefined' || str === null) return ''

      const breakTag = '<br />'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    },
  }
}
</script>

<style lang="scss" scoped>
.contact-page {
  .split-page {
    padding-bottom: 140px;
    .image-container {
      @apply relative;
      margin-bottom: 56px;

      img {
        @apply w-full;
      }

      img.logo-svg {
        @apply absolute;
        @apply bg-transparent;
        left: 10px;
        bottom: -15px;
        width: 38px;
        height: 38px;
      }
    }

    .contact-info-container, .about-nick-container {
      @apply text-left;
      font-family: UniversNext, serif;
    }

    .contact-info-container {
      ul.links > li, ul.links > li > a, .address {
        font-size: 13px;
        font-family: UniversNext, serif;
        line-height: 26px;
      }

      ul.links {
        margin-bottom: 35px;
        li {
          a {
            @apply relative;
            @apply inline-block;
            @apply uppercase;

            &::after {
              @apply absolute;
              left: 0;
              bottom: 2px;
              content: '';
              width: 100%;
              height: 1px;
              background-color: #303030;
            }
          }
        }
      }

      .address {
        margin-bottom: 35px;
      }
    }

    .about-nick-container {
      font-family: UniversNext, serif;
    }
  }

  /* Tablet */
  @media screen and (min-width: 768px) {
    padding-left: 27px;
    padding-right: 27px;

    .split-page {
      @apply flex;
      @apply flex-wrap;

      .image-container {
        @apply w-3/5;
        padding-right: 27px;

        img.logo-svg {
          width: 43px;
          height: 43px;
          left: 18px;
          bottom: -16px;
        }
      }

      .contact-info-container {
        @apply w-2/5;
        font-size: 14px;
        padding-left: 74px;
      }

      .about-nick-container {
        /* w-3/5 + 74px padding left of the .contact-info-container */
        width: calc(60% + 74px);
      }
    }
  }

  /* Desktop */
  @media screen and (min-width: 1300px) {
    padding-left: 0;

    .split-page {
      @apply block;
      padding-left: 229px;
      content: "";
      display: table;
      clear: both;

      .image-container {
        @apply inline-block;
        @apply float-left;
        padding-right: 157px;

        img.logo-svg {
          width: 60px;
          height: 60px;
          left: 29px;
          bottom: -23px;
        }
      }

      .contact-info-container {
        @apply inline-block;
        @apply h-full;
        padding-left: 0;
      }

      .about-nick-container {
        @apply inline-block;
        @apply w-2/5;
      }
    }
  }
}
</style>
